.performers-list__item-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
  padding: 10px 0px;
}
.performers-list__item-name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  color: var(--title-color);
}
.performers-list__item-text {
  margin: 5px 0px 0px 0px;
  font-size: 16px;
  line-height: 1.3;
  max-width: calc(100% - 40px - 80px - 20px);
}
.draggable-item_type_dragging .performers-list__item-info .performers-list__item-text {
  color: var(--white-color);
}