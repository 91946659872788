.control-feedback__title {
  margin: 20px 0px 0px 0px;
  font-size: 24px;
  line-height: 1.3;
}
.control-feedback___subtitle {
  margin: 10px 0px 0px 0px;
  font-size: 18px;
  line-height: 1.3;
}
.control-feedback__item {
  margin: 0px 0px 15px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  transition: .3s;
  position: relative;
}
.control-feedback__item-count {
  margin: 0px 30px 0px 10px;
  color: var(--border-card-color);
  font-weight: 400;
  font-size: 18px;
}
.control-feedback__item-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}
.control-feedback__item-name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  color: var(--title-color);
}
.control-feedback__item-text {
  margin: 5px 0px 0px 0px;
  font-style: normal;
  font-size: 16px;
  line-height: 1.3;
  color: var(--subtitle-color);
}