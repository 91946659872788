.list {
  width: 100%;
  margin: 12px 0px 0px 0px;
  max-height: 300px;
  overflow-y: auto;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
.list__item {
  width: calc(100% - 4px);
  margin: 0px 4px 12px 0px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  box-sizing: border-box;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  transition: .3s;
  position: relative;
}
.list__item:last-of-type {
  margin: 0px;
}
.list__number {
  margin: 0px 16px 0px 0px;
  color: var(--subtitle-color);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  flex-shrink: 0;
}
.list__info {
  margin: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.list__title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
}

.list__control {
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  padding: 0px 8px;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: var(--main-color);
  transition: .7s ease-in-out;
  z-index: 100;
}
.list__item:hover .list__control {
  display: flex;
  align-items: center;
}
.list__btn {
  margin: 0px 8px 0px 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.2px;
  padding: 7px 12px;
  max-width: max-content;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.list__btn:last-of-type {
  margin: 0px;
}
.list__btn:hover {
  cursor: pointer;
  opacity: .7
  ;
}
.list__btn_type_edit {
  background-color: var(--secondary-light-color);
}
.list__btn_type_remove {
  background-color: var(--third-light-color);
}