html, body {
  user-select: auto !important;
}
textarea {
  font-family: Arial, sans-serif;
}
input {
  font-family: Arial, sans-serif;
}
.page {
  --title-color: #404040;
  --subtitle-color: #4F5665;
  --white-color: #ffffff;
  --background-main-color: #fffefd;
  --background-secondary-color: #fafafa;
  --background-third-color: #F3F3F3;
  --main-color: #4A56E2;
  --main-light-color: #5BC1EE;
  --secondary-color: #5EB9AF;
  --secondary-light-color: #3ABD98;
  --third-light-color: #FA7565;
  --fourth-light-color: #FBB444;
  --five-light-color: #8B00FF;
  --border-card-color: #DDDDDD;
  color: var(--subtitle-color);
  background-color: var(--background-main-color);
  box-sizing: border-box;
  font-family: 'Raleway', Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  position: relative;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}
.container {
  max-width: 1280px;
  margin: 0px auto;
}
.font_weight_bold {
  font-weight: bold !important;
}
.btn {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 10px 45px 10px 15px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.btn:hover {
  opacity: .7;
  cursor: pointer;
}
.btn_margin_top {
  margin: 12px 0px 0px 0px;
}
.btn_margin_right {
  margin-right: 12px;
}
.btn_margin_left-auto {
  margin-left: auto;
}
.btn_width_content {
  width: max-content;
}
.btn_type_choose {
  background-color: var(--third-light-color);
}
.btn_type_choose::after {
  content: '';
  background-image: url('../../images/quiz/one-answer-icon.png');
  background-size: 24px;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 13px;
  top: 11px;
  z-index: 5;
}
.btn_type_save {
  background-color: var(--third-light-color);
}
.btn_type_save::after {
  content: '';
  background-image: url('../../images/save-white.png');
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 11px;
}
.btn_type_copy {
  background-color: var(--fourth-light-color);
}
.btn_type_copy::after {
  content: '';
  background-image: url('../../images/copy.png');
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 13px;
  z-index: 5;
}
.btn_type_export-word {
  background-color: var(--main-color);
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  padding: 20px 80px 20px 30px;
  text-transform: none;
  text-decoration: none;
}
.btn_type_export-word::after {
  content: '';
  background-image: url('../../images/word-white.png');
  background-size: 45px;
  background-repeat: no-repeat;
  width: 45px;
  height: 45px;
  position: absolute;
  right: 15px;
  top: 9px;
  z-index: 5;
}
.btn_type_export-pdf {
  background-color: var(--third-light-color);
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  padding: 20px 80px 20px 30px;
  text-transform: none;
  text-decoration: none;
}
.btn_type_export-pdf::after {
  content: '';
  background-image: url('../../images/pdf-white.png');
  background-size: 45px;
  background-repeat: no-repeat;
  width: 45px;
  height: 45px;
  position: absolute;
  right: 15px;
  top: 9px;
  z-index: 5;
}
.btn_type_export-png {
  background-color: var(--secondary-light-color);
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  padding: 20px 80px 20px 30px;
  text-transform: none;
  text-decoration: none;
}
.btn_type_export-png::after {
  content: '';
  background-image: url('../../images/png-white.png');
  background-size: 45px;
  background-repeat: no-repeat;
  width: 45px;
  height: 45px;
  position: absolute;
  right: 15px;
  top: 9px;
  z-index: 5;
}
.btn_type_upload {
  background-color: var(--main-color);
  margin-top: 15px;
}
.btn_type_upload::after {
  content: '';
  background-image: url('../../images/upload.png');
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 6px;
  z-index: 5;
}
.btn_type_next {
  background-color: var(--secondary-color);
}
.btn_type_next::after {
  content: '';
  background-image: url('../../images/arrows/arrow-right-white.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 13px;
  z-index: 5;
}
.btn_type_add {
  background-color: var(--main-color);
}
.btn_type_add::after {
  content: '';
  background-image: url('../../images/add.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 10px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.btn_type_edit {
  background-color: var(--fourth-light-color);
}
.btn_type_edit::after {
  content: '';
  background-image: url('../../images/edit-plan.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 10px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.btn_type_import {
  background-color: var(--main-color);
}
.btn_type_import::after {
  content: '';
  background-image: url('../../images/import.png');
  background-size: 22px;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 12px;
  top: 10px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.btn_type_password {
  background-color: var(--main-color);
}
.btn_type_password::after {
  content: '';
  background-image: url('../../images/padlock.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 11px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.btn_type_back {
  background-color: var(--secondary-color);
}
.btn_type_back::after {
  content: '';
  background-image: url('../../images/back-white.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 13px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.btn_type_confirm {
  background-color: var(--secondary-color);
}
.btn_type_confirm::after {
  content: '';
  background-image: url('../../images/check.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 13px;
  z-index: 5;
}
.btn_type_delete {
  background-color: var(--third-light-color);
}
.btn_type_delete::after {
  content: '';
  background-image: url('../../images/delete.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 5;
}
.btn_type_loading {
  pointer-events: none;
  user-select: none;
  background-color: var(--border-card-color);
}
.btn_type_block {
  pointer-events: none;
  user-select: none;
  background-color: var(--border-card-color);
  color: var(--background-third-color);
}
.btn_type_block::after {
  content: '';
  background-image: url('../../images/save-block.png');
  background-repeat: no-repeat;
}
.request-message {
  width: 100%;
  min-height: 20px;
  margin: 4px 0px 0px 0px;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  transition: .3s ease-in-out;
}
.request-message_align_center {
  text-align: center;
}
.request-message_type_hide {
  opacity: 0;
}
.request-message_type_show {
  opacity: 1;
}
.request-message_type_error {
  color: var(--third-light-color);
}
.request-message_type_success {
  color: var(--secondary-color);
}
.request-node {
  margin: 20px 0px 0px 0px;
  padding: 10px 20px;
  border-radius: 3px;
  display: block;
  max-width: max-content;
}
.request-node_type_error {
  background-color: rgba(250, 117, 101, .1);
  color: var(--third-light-color);
}
.request-node_type_success {
  background-color: rgba(58, 189, 152, 0.1);
  color: var(--secondary-light-color);
}
.radio {
  display: block;
  cursor: pointer;
  margin: 16px 0px 0px 0px;
}
.radio input {
  display: none;
}
.radio input + span {
  font-size: 16px;
  padding-left: 22px;
  padding-top: 2px;
  display: block;
  position: relative;
}
.radio input + span:not(:empty) {
  padding-left: 30px;
}
.radio input + span:before, .radio input + span:after {
  content: '';
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 50%;
  left: 0;
  top: 0;
  position: absolute;
}
.radio input + span:before {
  background: var(--border-card-color);
  transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.radio input + span:after {
  background: var(--white-color);
  transform: scale(0.78);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}
.radio input:checked + span:before {
  transform: scale(1.04);
  background: var(--main-color);
}
.radio input:checked + span:after {
  transform: scale(0.4);
  transition: transform 0.3s ease;
}
.radio:hover input + span:before {
  transform: scale(0.92);
}
.radio:hover input + span:after {
  transform: scale(0.74);
}
.radio:hover input:checked + span:after {
  transform: scale(0.4);
}
.checkbox {
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.checkbox input {
  display: none;
}
.checkbox input + span {
  font-size: 16px;
  padding-left: 22px;
  padding-top: 3px;
  display: block;
  position: relative;
}
.checkbox input + span:not(:empty) {
  padding-left: 30px;
}
.checkbox input + span:before, .checkbox input + span:after {
  content: '';
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 20%;
  left: 0;
  top: 0;
  position: absolute;
}
.checkbox input + span:before {
  background: var(--border-card-color);
  transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.checkbox input + span:after {
  background: var(--white-color);
  transform: scale(0.78);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}
.checkbox input:checked + span:before {
  transform: scale(1.04);
  background: var(--main-color);
  background-image: url('../../images/checkbox-img.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
}
.checkbox input:checked + span:after {
  transform: scale(0);
  transition: transform 0.1s ease;
}
.checkbox:hover input + span:before {
  transform: scale(0.92);
}
.checkbox:hover input + span:after {
  transform: scale(0.74);
}
.checkbox:hover input:checked + span:after {
  transform: scale(0);
}
.form {
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.form_width-600 {
  max-width: 600px;
}
.form_margin_top-20 {
  margin: 20px 0px 0px 0px;
}
.form__field {
  width: 100%;
  margin: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.form__field_margin_top-8 {
  margin-top: 8px;
}
.form__field_margin_top-16 {
  margin-top: 16px;
}
.form__input-caption {
  margin: 0px 0px 4px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
.form__input {
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  border: 2px solid var(--border-card-color);
  transition: all .3s ease-in-out;
}
.form__input:focus {
  border: 2px solid var(--secondary-color);
}
.form__input:hover {
  border: 2px solid var(--secondary-color);
}
.form__input_type_error:focus {
  border: 2px solid var(--third-light-color);
}
.form__input_type_error:hover {
  border: 2px solid var(--third-light-color);
}
.form__input_type_block {
  background-color: var(--background-third-color);
}
.form__input_type_block:hover {
  border: 2px solid var(--border-card-color);
}
.form__input::placeholder {
  margin: 0px;
  font-size: 14px;
  line-height: 18px;
  color: var(--border-card-color);
}
.form__input-error {
  margin: 4px 0px 0px 12px;
  min-height: 14px;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--third-light-color);
  opacity: 0;
  transition: .3s ease-in-out;
}
.form__input-error_active {
  opacity: 1;
}
.form__textarea {
  resize: none;
  width: 100%;
  min-height: 120px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  border: 2px solid var(--border-card-color);
  transition: all .3s ease-in-out;
}
.form__textarea:focus {
  border: 2px solid var(--secondary-color);
}
.form__textarea:hover {
  border: 2px solid var(--secondary-color);
}
.form__textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--border-card-color);
}
.form__btn-container {
  margin: 0px;
  width: 100%;
  position: relative;
  display: flex;
}
.form__btn-container_margin_top-20 {
  margin-top: 20px;
}
.form__btn-container_margin_top-auto {
  margin-top: auto;
}
.form__btn-item {
  margin: 0px 20px 0px 0px;
  display: flex;
  flex-direction: column;
}
.form__btn-item:last-of-type {
  margin: 0px;
}
.form__btn-item_margin_auto {
  margin: 0px auto;
}
.form__btn-item_margin_auto:last-of-type {
  margin: 0px auto;
}
.search {
  margin: 10px 0px;
  width: 100%;
  position: relative;
}
.input-search {
  background-color: #fff;
  border-radius: 100px;
  padding: 10px 50px 10px 20px;
  width: 100%;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.15px;
  color: var(--title-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  transition: all ease-in-out .3s;
}
.input-search:focus {
  border: 2px solid var(--main-color);
}
.search::after {
  content: '';
  background-size: 20px;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 10px;
  z-index: 5;
  background-image: url('../../images/input-search-icon.png');
}

.select-wrapper {
  width: calc(100% - 20px);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  border-radius: 100px;
  position: relative;
  color: var(--title-color);
  background-color: var(--white-color);
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 1.5px;
  margin: 0px;
  transition: .3s ease-in-out;
}
.select-wrapper > select {
  padding: 12px 22px;
  width: 100%;
  background-color: transparent;
  border-radius: 100px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 1.5px;
  color: var(--title-color);
  background-color: var(--white-color);
  outline: none;
  appearance: none;
  transition: .3s ease-in-out;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select-option {
  max-width: 400px;
}

.select-wrapper > select:focus {
  outline: none;
}

.select-wrapper > select > option {
  outline: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 1.5px;
}

.select-wrapper > select::-ms-expand{
  display: none;
}
.select-arrow {
  position: absolute;
  border: 8px solid transparent;
  border-bottom: 0;
  right: 11px;
}
.select-arrow:nth-child(2) {
  top: 18px;
  border-top-color: black;
}
.select-arrow:nth-child(3) {
  top: 15px;
  border-top-color: #FFF;
}
.tabs {
  margin: 40px 0px;
}
.tab-list {
  border-bottom: 1px solid var(--border-card-color);
  margin: 0 0 10px;
  padding: 0;
}
.tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 8px 20px;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
}
.select {
  width: 100%;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
  background-color: rgba(94, 185, 175, .3);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  background-color: rgba(94, 185, 175, 1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

@media all and (max-width: 1400px) {
  .container {
    padding: 0px 32px;
  }
}

@media all and (max-width: 967px) {
  .btn {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.2px;
    padding: 10px 35px 10px 10px;
  }
  .btn_type_next::after {
    background-size: 15px;
    width: 15px;
    height: 15px;
    right: 12px;
    top: 13px;
  }
}

@media all and (max-width: 760px) {
  .container {
    padding: 0px 16px;
  }
  .form {
    width: 100%;
  }
}