.add-node__main-title {
  font-size: 24px;
}
.add-node__title {
  text-align: left;
  margin: 0px auto 0px 0px;
}
.add-node__subtitle {
  margin: 5px auto 15px 0px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  color: var(--subtitle-color);
}
.node__input {
  width: calc(100% - 60px);
  justify-content: space-between;
  padding: 15px 30px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15px;
  color: var(--title-color);
  letter-spacing: 1.2px;
  margin: 0px 0px 10px 0px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.add-node__caption {
  margin: 10px auto 0px 0px;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
  max-width: none;
}
.add-zoon__error {
  position: relative;
  width: 100%;
  margin: 10px 0px 5px 0px;
}
.add-zoon__error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--third-light-color);
  position: absolute;
  top: 0px;
  left: 0px;
  transition: .3s ease-in-out;
}
.add-zoon__error-message_type_hide {
  opacity: 0;
}
.add-zoon__error-message_type_show {
  opacity: 1;
}