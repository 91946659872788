.educational-material-item {
  margin: 0px;
}
.educational-material-item__theme {
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  margin: 20px 0px 0px 0px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
  width: 100%;
}
.educational-material-item__theme-type {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1.2px;
  padding: 5px 10px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  pointer-events: none;
  user-select: none;
  max-width: max-content;
}
.educational-material-item__theme-type_type_lec {
  background-color: var(--main-color);
  color: var(--white-color);
}
.educational-material-item__theme-type_type_pr {
  background-color: var(--secondary-color);
  color: var(--white-color);
}
.educational-material-item__theme-type_type_lab {
  background-color: var(--third-light-color);
  color: var(--white-color);
}
.educational-material-item__theme-name {
  margin: 7px 0px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 1.2px;
  text-align: left;
  color: var(--title-color);
}
.educational-material-item__sections {
  display: flex;
}
.educational-material-item__section-steps {
  margin: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.educational-material-item__grid {
  display: grid;
  grid-template-columns: repeat(1fr, 400px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-rows: min-content;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  list-style-type: none;
}
.educational-material-item__grid-item {
  width: 100%;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  padding: 20px;
  display: flex;
  align-items: center;
}
.educational-material-item__grid-item_type_one {
  grid-area: 1 / 1 / 3 / 5;
  display: flex;
  flex-direction: column;
}
.educational-material-item__grid-item_type_two {
  grid-area: 3 / 1 / 5 / 5;
  display: flex;
  flex-direction: column;
}
.educational-material-item__grid-item_type_five {
  grid-area: 1 / 5 / 3 / 6;
  display: flex;
  flex-direction: column;
}
.educational-material-item__grid-item_type_six {
  grid-area: 3 / 5 / 5 / 6;
}
.educational-material-item__upload-document {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.educational-material-item__documents-list {
  margin: 0px;
  width: 100%;
  list-style-type: none;
  padding: 0px;
}
.educational-material-item__documents-item {
  margin: 0px auto 10px 0px;
  display: flex;
  align-items: center;
}
.educational-material-item__documents-item-count {
  margin: 0px 10px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 1.2px;
  color: var(--subtitle-color);
}
.educational-material-item__documents-link {
  margin: 0px 10px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  background-color: rgba(74, 86, 226, 0.1);
  color: var(--main-color);
  padding: 5px 10px;
  border-radius: 3px;
  letter-spacing: 1.2px;
  text-decoration: none;
  transition: .3s ease-in-out;
}
.educational-material-item__documents-link:hover {
  background-color: var(--main-color);
  color: var(--white-color);
  cursor: pointer;
}
.educational-material-item__documents-caption {
  margin: 0px auto 10px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
  color: var(--border-card-color);
  letter-spacing: 1.2px;
}
.educational-material-item__title {
  margin: 30px 0px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}
.educational-material-item__document-title {
  width: 100%;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}
.educational-material__item_btn {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1.2px;
  padding: 5px 10px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  pointer-events: none;
}
.educational-material__item_btn:hover {
  user-select: none;
}
.educational-material__item_btn_type_lection {
  background-color: rgba(74, 86, 226, 0.1);
  color: var(--main-color);
  pointer-events: all;
}
.educational-material__item_btn_type_lection:hover {
  background-color: var(--main-color);
  color: var(--white-color);
  cursor: pointer;
  user-select: auto;
}
.educational-material__item_btn_type_practical {
  background-color: rgba(58, 189, 152, 0.1);
  color: var(--secondary-color);
  pointer-events: all;
}
.educational-material__item_btn_type_practical:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
  cursor: pointer;
  user-select: auto;
}
.educational-material__item_btn_type_lab {
  background-color: rgba(250, 117, 101, .1);
  color: var(--third-light-color);
  pointer-events: all;
}
.educational-material__item_btn_type_lab:hover {
  background-color: var(--third-light-color);
  color: var(--white-color);
  cursor: pointer;
  user-select: auto;
}
.educational-material__item_btn_type_lec-active {
  background-color: var(--main-color);
  color: var(--white-color);
  cursor: pointer;
  user-select: auto;
}
.educational-material__item_btn_type_lec-active:hover {
  opacity: .7;
}
.educational-material__item_btn_type_pr-active {
  background-color: var(--secondary-color);
  color: var(--white-color);
  cursor: pointer;
  user-select: auto;
}
.educational-material__item_btn_type_pr-active:hover {
  opacity: .7;
}
.educational-material__item_btn_type_lab-active {
  background-color: var(--third-light-color);
  color: var(--white-color);
  cursor: pointer;
  user-select: auto;
}
.educational-material__item_btn_type_lab-active:hover {
  opacity: .7;
}
.educational-material-item__btn-back {
  margin: 20px 0px 0px 0px;
}
.educational-material-item__btn-export {
  margin: 10px 0px 0px 0px;
  display: block;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 10px 45px 10px 15px;
  max-width: max-content;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.educational-material-item__btn-export::after {
  content: '';
  background-image: url('../../../../../images/word-white.png');
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
}
.educational-material-item__steps {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.educational-material-item__step {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0px 0px 32px 0px;
}
.educational-material-item__step:last-of-type {
  margin: 0px;
}
.educational-material-item__step-number {
  margin: 0px 20px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.3;
  color: var(--border-card-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 2px solid var(--border-card-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.educational-material-item__step-description {
  display: flex;
  flex-direction: column;
}
.educational-material-item__name {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
}
.educational-material-item__choose-file {
  margin: 15px 0px 0px 0px;
  display: block;
  height: 35px;
}
.educational-material-item__btn-save {
  margin: 0px;
  max-width: max-content;
}
.educational-material-item__btn-remove {
  margin: 0px 0px 0px auto;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  position: relative;
  background-image: url('../../../../../images/cancel.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  background-color: inherit;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}
.educational-material-item__btn-remove:hover {
  opacity: .7;
  cursor: pointer;
}
.educational-material-item_btn_add_material {
  margin: auto 0px 0px 0px;
  background-color: var(--secondary-color);
}
.educational-material-item__documents-approval {
  margin: 12px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.educational-material-item__documents-approval-text {
  margin: 0px 0px 5px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--secondary-light-color);
}
.educational-material-item__documents-approval-text:last-of-type {
  margin: 0px;
}
.educational-material-item__save {
  width: 100%;
  display: flex;
  align-items: center;
}
.educational-material-item__node {
  margin: 0px 0px 0px 16px;
}
.educational-material-item__documents-approval-name {
  margin: 5px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
}
.educational-material-item__documents-approval-btn {
  margin: 10px 0px 0px 0px;
  width: max-content;
  padding: 10px 15px 10px 15px;
  background-color: var(--third-light-color);
}