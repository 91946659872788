.import-knowledge__title {
  margin: 10px 0px 10px 0px;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  color: var(--title-color);
}
.import-knowledge__field {
  margin: 0px 0px 20px 0px;
  background: #FFFFFF;
  border: 1px solid var(--border-card-color);
  box-sizing: border-box;
  backdrop-filter: blur(8px);
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.import-knowledge__import {
  background-color: #fff;
  border-radius: 100px;
  border: none;
  padding: 0 25px 0px 25px;
  width: 100%;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #1A1B22;
}
.import-knowledge__import:focus {
  border: 2px solid var(--main-color);
  margin: -2px;
}
.import-knowledge__import::placeholder {
  color: var(--border-card-color);
}
.import-knowledge__search-btn {
  background: var(--main-color);
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border-radius: 100px;
  border: none;
  margin: -1px -1px -1px -167px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  padding: 10px 54px;
  outline: none;
  cursor: pointer;
  transition: .3s;
}
.import-knowledge__search-btn:hover {
  opacity: .7;
}
.import-knowledge__list {
  padding: 0px;
  margin: 0px 0px 0px 0px;
  list-style-type: none;
}
.import-knowledge__item {
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  margin: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 10px 20px;
  transition: .3s ease-in-out;
}
.import-knowledge__item:hover {
  cursor: pointer;
}
.import-knowledge__item_type_current {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
}
.import-knowledge__tags-container {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.import-knowledge__tag {
  margin: 0px 5px 0px 0px;
  color: var(--white-color);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.2px;
  padding: 5px 10px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  pointer-events: none;
  background-color: var(--main-color);
  user-select: none;
}
.import-knowledge__tag:last-of-type {
  margin: 0px;
}
.import-knowledge__item_type_current .import-knowledge__tag {
  background-color: var(--third-light-color);
}
.import-knowledge__tag_type_block {
  background-color: var(--border-card-color) !important;
}
.import-knowledge__item-name {
  margin: 10px 0px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
  transition: .3s ease-in-out;
}
.import-knowledge__item_type_current .import-knowledge__item-name {
  color: var(--white-color);
}
.import-knowledge__item-description {
  margin: 10px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
  transition: .3s ease-in-out;
}
.import-knowledge__item_type_current .import-knowledge__item-description {
  color: var(--white-color);
}
.import-knowledge__btn-save {
  margin: 20px auto 20px 0px;
}
.import-knowledge__caption {
  margin: 10px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
  width: 100%;
}