.avatar {
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  cursor: pointer;
  padding: 0;
  border: 0;
  outline: none;
  background-color: inherit;
}
.avatar__user-img {
  margin: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-position: center;
  object-fit: contain;
}
.avatar::after {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: url('../../images/edit.png');
  background-size: 40px;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: .25s;
  opacity: 0;
  pointer-events: none;
}
.avatar:hover::after {
  opacity: .7;
}