.draggable-item {
  box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  margin: 0px 0px 15px 0px;
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: hidden;
}
.draggable-item_type_dragging {
  border: 1px solid var(--main-color);
  background-color: var(--main-color);
  color: #fff;
}
.draggable-item__count {
  margin: 0px 0px 0px 0px;
  color: var(--border-card-color);
  font-weight: 400;
  font-size: 18px;
  padding: 20px 30px;
}
.draggable-item__text {
  margin: 0px;
  padding: 20px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
  max-width: calc(100% - 170px);
  transition: max-width .7s ease-in-out;
}
.draggable-item__text_type_short {
  max-width: calc(100% - 410px);
}
.draggable-item_type_dragging .draggable-item__text {
  color: var(--white-color);
}
.draggable-item__buttons {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px auto;
  padding: 0px 20px 0px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  height: 100%;
  overflow-x: hidden;
  background-color: var(--main-color);
  transition: .7s ease-in-out;
  z-index: 100;
  cursor: auto;
  width: 30px;
}
.draggable-item__buttons_type_show {
  width: 280px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.draggable-item__btn {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 7px 12px;
  max-width: max-content;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.draggable-item__btn:hover {
  opacity: .7;
  cursor: pointer;
}
.draggable-item__btn-edit {
  background-color: var(--secondary-light-color);
  margin: 0px 7px 0px 0px;
}
.draggable-item__btn-delete {
  background-color: var(--third-light-color);
}
.draggable-item__btn-menu {
  margin: 0px 20px 0px 0px;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  position: relative;
  background-image: url('../../../images/menu.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  background-color: inherit;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.draggable-item__btn-menu:hover {
  opacity: .7;
  cursor: pointer;
}
.draggable-item__btn-menu_type_show {
  transform: rotate(90deg);
}
