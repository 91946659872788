.typical-structure {
  width: 100%;
}
.typical-structure__buttons {
  margin: 30px auto 0px;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.typical-structure__add {
  margin: 20px 0px 0px 0px;
  width: 100%;
  background-color: var(--main-color);
  overflow-y: hidden;
  transition: .3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.typical-structure__add-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.typical-structure__add-input {
  width: calc(100% - 60px);
  justify-content: space-between;
  padding: 15px 30px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15px;
  color: var(--title-color);
  letter-spacing: 1.2px;
  margin: 0px 0px 10px 0px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.typical-structure__add-input::placeholder {
  font-size: 14px;
}
.typical-structure__add_type_show {
  height: 170px;
}
.typical-structure__add_type_hide {
  height: 0px;
}
.typical-structure__btn-type-add {
  margin: 15px 0px 0px 0px;
  max-width: max-content;
}
.typical-structure__btn-add_type_hide::after {
  transform: rotate(0deg);
}
.typical-structure__btn-add_type_show::after {
  transform: rotate(180deg);
}
.typical-structure__container {
  width: 100%;
}
.typical-structure__subtitle {
  margin: 20px auto 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}
.typical-structure__part-list {
  list-style-type: none;
  padding: 0px;
}