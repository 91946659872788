.program-structure__hours-list {
  list-style-type: none;
  margin: 20px 0px 10px 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.program-structure__hours-item {
  margin: 0px 40px 10px 0px;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px;
  min-width: 246px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s ease-in-out;
}
.program-structure__hours-item:last-of-type {
  margin: 0px 0px 10px 0px;
}
.program-structure__hours-item_type_choose:hover {
  border: 2px solid var(--main-color);
  cursor: pointer;
}
.program-structure__hours-item_type_active {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
}
.program-structure__hours-item_type_active:hover {
  opacity: .7;
}
.program-structure__hours-name {
  margin: 0px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.3;
  font-weight: bold;
  color: var(--title-color);
  transition: .3s ease-in-out;
}
.program-structure__hours-name_type_active {
  color: var(--white-color);
}
.program-structure__hours_type_success {
  color: var(--secondary-color);
}
.program-structure__hours_type_error {
  color: var(--third-light-color);
}
.program-structure__hours_type_main {
  color: var(--main-color);
}