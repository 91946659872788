.question-type-popup__list {
  margin: 5px 0px 20px 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
.question-type-popup__item {
  box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  margin: 0px 0px 15px 0px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 10px 20px;
  transition: .3s ease-in-out;
}
.question-type-popup__item:hover {
  border: 2px solid var(--main-color);
  cursor: pointer;
}
.question-type-popup__item_type_active {
  border: 2px solid var(--main-color);
  background-color: var(--main-color);
  user-select: none;
  pointer-events: none;
}
.question-type-popup__item-img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  margin-right: 20px;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}
.question-type-popup__item-text {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
  color: var(--title-color);
}
.question-type-popup__item-text_type_active {
  color: var(--white-color);
}