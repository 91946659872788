.knowledge-material {
  margin: 40px 0px 0px 0px;
}
.knowledge-material__btn-back {
  margin: 0px 0px 20px 0px;
}
.knowledge-material__description {
  display: flex;
  align-items: center;
  margin: 0px 0px 20px 0px;
}
.knowledge-material__name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}
.knowledge-material__symbol {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 0px 15px 0px 0px;
  background-color: var(--third-light-color);
}

.questions__container {
  display: flex;
  justify-content: space-between;
  min-height: 80vh;
  width: 100%;
  border-radius: 10px;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.questions__main {
  width: 100%;
  padding: 20px;
}
.questions__control {
  display: flex;
  justify-content: space-between;
}
.questions__control-edit {
  display: flex;
  position: relative;
}
.questions_save-caption {
  position: absolute;
  bottom: -30px;
  left: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
  transition: .3s ease-in-out;
}
.questions_save-caption_type_success {
  color: var(--secondary-color);
}
.questions_save-caption_type_error {
  color: var(--third-light-color);
}
.questions_save-caption_type_show {
  opacity: 1;
}
.questions_save-caption_type_hide {
  opacity: 0;
}
.questions__btn_type_save {
  background-color: var(--secondary-color);
  margin: 0px 10px 0px 0px;
}
.questions__btn_type_copy {
  margin: 0px 10px 0px 0px;
}
.questions__btn_type_block {
  pointer-events: none;
  user-select: none;
  background-color: var(--background-main-color);
  color: var(--background-third-color);
}
.questions__btn_type_block::after {
  content: '';
  background-image: url('../../../../images/save-block.png');
  background-repeat: no-repeat;
}
.questions__nav-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  transition: .3s ease-in-out;
  width: 100%;
  max-width: 300px;
  padding: 20px;
  background-color: var(--main-color);
  border-radius: 10px;
}
.questions__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.questions__title {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: var(--white-color);
}
.questions__count {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.3;
  text-align: left;
  color: var(--white-color);
}
.questions__nav-input {
  background-color: #fff;
  border-radius: 100px;
  padding: 10px 50px 10px 20px;
  width: calc(100% - 70px);
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.15px;
  color: var(--title-color);
  border: 2px solid var(--border-card-color);
}
.questions__nav-list {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow-y: auto;
}
.questions__nav-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  transition: .3s ease-in-out;
  margin-top: 5px;
  margin-right: 5px;
}
.questions__nav-item_type_active {
  background-color: rgba(255, 255, 255, .3);
  cursor: auto;
  user-select: none;
  pointer-events: none;
}
.questions__nav-item:hover {
  background-color: rgba(255, 255, 255, .3);
  cursor: pointer;
}
.questions__nav-item-img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-position: center;
  margin: 0px 10px 0px 0px;
}
.questions__nav-item-text {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3;
  text-align: left;
  color: var(--white-color);
  display: -webkit-box;
  -webkit-line-clamp: 3; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}