.homepage-methodology {
  width: 100%;
  margin: 0px;
}
.homepage-methodology__list {
  width: 100%;
  margin: 30px 0px 50px 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.homepage-methodology__item {
  position: relative;
  margin: 0px 10px 20px 0px;
  padding: 20px 28px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 400px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  transition: all .3s ease-in-out;
}
.homepage-methodology__item:last-of-type {
  margin: 0px 0px 20px 0px;
}
.homepage-methodology__item:hover {
  transform: scale(1.02);
  cursor: pointer;
}
.homepage-methodology__item-info {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -20px;
  right: -20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-image: url('../../../images//main-page/main-page-info.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  background-color: var(--main-color);;
  border-radius: 50%;
  transition: all .7s ease-in-out;
}
.homepage-methodology__item-title {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--title-color);
}
.homepage-methodology__item-text {
  margin: 20px 0px 20px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--subtitle-color);
}
.homepage-methodology__item-link {
  margin: auto 0px 0px 0px;
  padding: 12px 20px;
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  background: var(--main-color);
  color: var(--white-color);
  text-decoration: none;
  border-radius: 8px;
  transition: all .3s ease-in-out;
}
.homepage-methodology__item-link:hover {
  cursor: pointer;
  opacity: .7;
}
.homepage-methodology__item-img {
  margin: 20px 0px 0px 0px;
  width: 100%;
  height: 300px;
}
.homepage-methodology__item-img_type_one {
  background-image: url('../../../images/main-page/methodology-one.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.homepage-methodology__item-img_type_two {
  background-image: url('../../../images/main-page/methodology-two.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.homepage-methodology__item-img_type_three {
  background-image: url('../../../images/main-page/methodology-three.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.homepage-methodology__item-img_type_four {
  background-image: url('../../../images/main-page/methodology-four.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}