@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

.preloader-popup {
  color: var(--subtitle-color);
  background-color: var(--background-secondary-color);
  box-sizing: border-box;
  font-family: 'Raleway', Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  min-height: 0;
  width: 100%;
	margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preloader-popup__circle {
	width:  74px;
	height: 74px;
	border: 1px solid #222;
	border-bottom-color: #888;
	border-radius: 50%;
  animation: spin .75s infinite linear;
  margin-bottom: 24px;
}
.preloader-popup__caption {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--subtitle-color);
}