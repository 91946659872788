.steps__list {
  margin: 50px 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.steps__item {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 10px;
  min-width: calc(16% - 17px);
  transition: .3s ease-in-out;
}
.steps__item:last-of-type {
  margin-right: 0px;
}
.steps__list .steps__item::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--border-card-color);
  top: 25px;
  left: -50%;
  z-index: 10;
}
.steps__list .steps__item:first-child::before {
  content: none;
}
.steps__list .steps__item::after {
  content: '';
  position: absolute;
  width: 0px;
  height: 3px;
  background-color: var(--main-color);
  top: 25px;
  left: -50%;
  z-index: 10;
  transition: .3s ease-in-out;
}
.steps__list .steps__item:first-child::after{
  content: none;
}
.steps__list .steps__item_type_previous::after{
  width: 100%;
}
.steps__number {
  margin: 0px auto;
  background-color: var(--border-card-color);
  border: 2px solid var(--border-card-color);
  color: var(--white-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  z-index: 55;
  transition: .3s ease-in-out;
}
.steps__number:hover {
  cursor: pointer;
  border: 2px solid var(--third-light-color);
}
.steps__number_type_previous {
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
}
.steps__number_type_active {
  background-color: var(--third-light-color);
  border: 2px solid var(--third-light-color);
}
.steps__number::after {
  content: '';
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: -22px;
  right: 0;
  text-align: center;
  width: 54px;
  height: 54px;
  border: 20px solid var(--background-secondary-color);
  border-radius: 50%;
  background-color: transparent;
  z-index: 20;
}
.steps__caption {
  margin: 15px auto 0px;
  max-width: 200px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

@media all and (max-width: 1367px) {
  .steps__caption {
    font-size: 14px;
    line-height: 19px;
    max-width: 150px;
  }
}

@media all and (max-width: 967px) {
  .steps__list {
    margin: 25px 0px;
  }
  .steps__caption {
    margin: 5px auto 0px;
    font-size: 12px;
    line-height: 16px;
    max-width: 150px;
    z-index: 55;
  }
  .steps__number {
    margin: 0px auto;
    width: 50px;
    height: 50px;
    font-size: 16px;
    line-height: 20px;
  }
  .steps__number::after {
    left: -15px;
    right: 0;
    width: 40px;
    height: 40px;
    border: 20px solid var(--background-secondary-color);
  }
}

@media all and (max-width: 760px) {
  .steps__list {
    margin: 20px 0px;
  }
  .steps__list .steps__item::before {
    top: 15px;
    left: -70%;
    height: 2px;
  }
  .steps__list .steps__item::after {
    top: 15px;
    left: -70%;
    height: 2px;
  }
  .steps__caption {
    display: none;
  }
  .steps__number {
    margin: 0px auto;
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 16px;
  }
  .steps__number::after {
    left: -7px;
    right: 0;
    width: 25px;
    height: 25px;
    border: 7px solid var(--background-secondary-color);
  }
}

@media all and (max-width: 360px) {
  .steps__list {
    margin: 15px 0px;
  }
  .steps__list .steps__item::before {
    top: 10px;
    left: -80%;
  }
  .steps__list .steps__item::after {
    top: 10px;
    left: -80%;
  }
  .steps__caption {
    display: none;
  }
  .steps__number {
    margin: 0px auto;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 16px;
  }
  .steps__number::after {
    left: -5px;
    right: 0;
    width: 20px;
    height: 20px;
    border: 5px solid var(--background-secondary-color);
  }
}