.zoon-list {
  min-height: 80vh;
}
.zoon-list__symbol-list {
  list-style-type: none;
  margin: 40px 0px 0px 0px;
  padding: 0px;
  display: flex;
}
.zoon-list__symbol-item {
  display: flex;
  align-items: center;
  margin-right: 55px;
}
.zoon-list__symbol-item:last-of-type {
  margin-right: 0px;
}
.zoon-list__symbol {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0px 15px 0px 0px;
}
.symbol-competence {
  background-color: var(--five-light-color);
}
.symbol-skill {
  background-color: var(--secondary-light-color);
}
.symbol-ability {
  background-color: var(--main-color);
}
.symbol-knowledge {
  background-color: var(--third-light-color);
}
.symbol-cross-knowledge {
  background-color: var(--fourth-light-color);
}
.zoon-list__symbol-name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
}
.zoon-list__title {
  margin: 30px 0px 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}
.zoon-list__list {
  padding: 0px 0px 0px 20px;
  list-style-type: none;
  margin: 0px;
  width: 100%;
}
.zoon-list__initial {
  padding: 0px;
}
.zoon-list__item {
  margin: 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}
.zoon-list__name {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
  padding-left: 25px;
  position: relative;
  transition: .3s ease-in-out;
}
.zoon-list__name:hover {
  cursor: pointer;
}
.name_type_competence {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  padding-left: 30px;
}
.name_type_competence:hover {
  color: var(--five-light-color);
}
.name_type_skill:hover {
  color: var(--secondary-light-color);
}
.name_type_ability:hover {
  color: var(--main-color);
}
.name_type_knowledge:hover {
  color: var(--third-light-color);
}
.name_type_cross-knowledge:hover {
  color: var(--fourth-light-color);
}

.name_type_competence::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0px 7px 0px 0px;
  background-color: var(--five-light-color);
}
.name_type_skill::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0px 7px 0px 0px;
  background-color: var(--secondary-light-color);
}
.name_type_ability::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0px 7px 0px 0px;
  background-color: var(--main-color);
}
.name_type_knowledge::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0px 7px 0px 0px;
  background-color: var(--third-light-color);
}
.name_type_cross-knowledge::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0px 7px 0px 0px;
  background-color: var(--fourth-light-color);
}
.name_type_invalid:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 0;
  margin: 0px 7px 0px 0px;
  background-color: inherit;
  background-image: url('../../../../../images/warning.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
}
.zoon-list__caption {
  font-weight: 700;
}
.zoon-list__count {
  margin-right: 5px;
}
.available-nodes__list {
  list-style-type: none;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  width: 100%;
}
.available-nodes__item {
  box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  margin: 0px 0px 15px 0px;
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  transition: .3s ease-in-out;
}
.available-nodes__item:hover {
  background-color: var(--border-card-color);
  cursor: pointer;
}
.available-nodes__count {
  margin: 0px 0px 0px 0px;
  color: var(--border-card-color);
  font-weight: 400;
  font-size: 18px;
  padding: 20px 30px;
}
.available-nodes__text {
  margin: 0px;
  padding: 20px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
  max-width: calc(100% - 170px);
  transition: max-width .7s ease-in-out;
}