.mto-item__list {
  list-style-type: none;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  width: 100%;
}
.mto-item__item {
  width: 100%;
  margin: 0px 0px 15px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  transition: .3s;
  position: relative;
}
.mto-item__item-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}
.mto-item__item-name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  color: var(--title-color);
}
.mto-item__item-description {
  margin: 5px 0px 0px 0px;
  font-size: 16px;
  line-height: 1.3;
  max-width: calc(100% - 40px - 80px - 20px);
  overflow-x: hidden;
}
.mto-item__btn-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  background-image: url("../../../../../images/cancel.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  background-color: inherit;
  width: 25px;
  height: 25px;
}
.mto-item__btn-delete:hover {
  opacity: .7;
  cursor: pointer;
}