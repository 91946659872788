.method__list {
  padding: 0px;
  margin: 30px 0px 0px 0px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.method__item {
  margin-bottom: 40px;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  padding: 40px 40px;
  margin: 0px 5px 20px 5px;
  display: flex;
  flex-direction: column;
  transition: .3s ease-in-out;
}
.method__item-info {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.method__item-title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.25;
  text-align: left;
  color: var(--title-color);
}
.method__item-description {
  padding: 0px;
  list-style-type: none;
  margin: 15px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
}
.method__item-caption {
  margin: 0px 50px 15px 0px;
  display: flex;
  align-items: center;
}
.method__item-caption:last-of-type {
  margin: 0px 0px 15px 0px;
}
.method__item-caption-img {
  width: 30px;
  height: 30px;
  object-position: center;
  object-fit: contain;
  margin: 0px 10px 0px 0px;
  user-select: none;
  pointer-events: none;
}
.method__item-caption-container {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.method__item-caption-title {
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.15;
  color: var(--main-color);
}
.method__item-control {
  margin: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}
.method__item-button-part {
  margin: 0px;
  background-color: var(--third-light-color);
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 10px 45px 10px 15px;
  max-width: max-content;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.method__item-button-part::after {
  content: '';
  background-image: url('../../images/arrows/arrow-right-white.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.button-part_type_show::after {
  transform: rotate(90deg);
}
.button-part_type_hide::after {
  transform: rotate(0deg);
}
.method__item-button-part:hover {
  cursor: pointer;
  opacity: .7;
}
.method__parts-list {
  padding: 0px;
  margin: 20px 0px 0px 0px;
  list-style-type: none;
  transition: .3s ease-in-out;
}
.method-list_type_show {
  height: auto;
  overflow-y: visible;
  transition: .3s ease-in-out;
}
.method-list_type_hide {
  height: 0px;
  overflow-y: hidden;
}
.method__parts-item {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-top: 1px solid var(--border-card-color);
}
.method__parts-img {
  margin: 0px 15px 0px 0px;
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
  user-select: none;
}
.method__parts-info {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.method__parts-name {
  margin: 0px 25px 0px 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.15;
  color: var(--title-color);
}
.method__parts-link {  
  margin: 0px 0px 0px auto;
  color: var(--white-color);
  background-color: var(--secondary-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 10px 40px 10px 15px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  text-decoration: none;
}
.method__parts-link:hover {
  opacity: .7;
}
.method__parts-link::after {
  content: '';
  background-image: url('../../images/arrows/arrow-right-white.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 12px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.ReactCollapse--collapse {
  transition: height 700ms;
}