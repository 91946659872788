.learning-plan__table {
  width: 100%;
}
.learning-plan__table-head {
  border-bottom: 1px solid var(--subtitle-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}
.learning-plan__table-container {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
.learning-plan__table-head-column-name {
  font-size: 24px;
  line-height: 1.3;
  font-weight: bold;
  margin: 0px;
  color: var(--title-color);
}
.learning-plan__table-head-column-caption {
  font-size: 16px;
  line-height: 1.3;
  font-weight: normal;
  color: var(--subtitle-color);
  text-align: center;
}
.learning-plan__table-head-column-caption_font_weight {
  font-weight: bold;
}
.learning-plan__table-head-column-caption_type_dragging {
  color: var(--white-color);
}
.learning-plan__table-head-column-caption_type_active {
  font-size: 16px;
  font-weight: bold;
  color: var(--main-color);
}
.learning-plan__table-head-column-caption_type_total {
  font-size: 16px;
  font-weight: bold;
  color: var(--main-color);
}
.learning-plan__table-body-row-name {
  font-size: 16px;
  line-height: 1.3;
  font-weight: normal;
  margin: 0px 20px 0px 0px;
  color: var(--subtitle-color);
}
.learning-plan__table-body-row-name_type_themes {
  padding-left: 20px;
}
.learning-plan__table-body-row-name_type_dragging {
  color: var(--white-color);
}
.table-head-column_type_name {
  width: 100%;
  padding: 5px 10px;
  border-right: 1px solid var(--subtitle-color);
}
.table-head-column_type_hours {
  padding: 5px 10px;
  flex-basis: 420px;
}
.learning-plan__table-head-column-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}
.learning-plan__table-body-row {
  padding: 0px 10px;
  margin: 5px 0px;
  border-right: 1px solid var(--subtitle-color);
}
.learning-plan__table-body-row:last-of-type {
  border-right: none;
}
.learning-plan__table-body-row_type_dragging {
  border-right: 1px solid var(--white-color);
}
.table-body-row_type_hours {
  flex-basis: 420px;
}
.table-body-row_type_name {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-body-row-name_type_title {
  font-size: 20px;
  line-height: 1.3;
  font-weight: bold;
  margin: 0px 20px 0px 0px;
  color: var(--title-color);
}
.learning-plan__table-head-column-item {
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.learning-plan__table-head-column-item_type_pp {
  width: 120px;
  border-right: 1px solid var(--subtitle-color);
  min-height: 50px;
}
.learning-plan__table-head-column-item_border_left {
  border-left: 1px solid var(--subtitle-color);
}
.learning-plan-pp__cell {
  margin: 8px 0px 0px 0px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.learning-plan-pp__cell-item {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.learning-plan-pp__cell-item_type_border {
  border-left: 1px solid var(--subtitle-color);
}
.learning-plan__table-body {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}
.learning-plan__table-row {
  display: flex;
  flex-direction: column;
}
.learning-plan__row-list {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
}
.learning-plan__btn-edit {
  margin: 0px 0px 0px auto;
  box-sizing: border-box;
  border: none;
  outline: none;
  position: relative;
  border-radius: 5px;
  background-image: url('../../../../images/edit-plan.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
  background-color: var(--main-color);
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}
.learning-plan__btn-edit:hover {
  opacity: .7;
  cursor: pointer;
}
.learning-plan__button_type_hide {
  display: none;
}
.learning-plan__btn-edit_type_dragging {
  background-color: var(--third-light-color);
}