.popup {
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0 , 0, .5);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  z-index: 100;
}
.popup_opened {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}
.popup__container {
  margin: 15vh auto 0px;
  padding: 34px 36px 28px;
  width: max-content;
  min-height: 114px;
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary-color);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  overflow-y: auto;
  scrollbar-color: var(--secondary-color) rgba(94, 185, 175, .3);
  scrollbar-width: 12px;
}
@-moz-document url-prefix() {
  .popup__container {
    overflow-y: scroll;
  }
}
.popup__close-button {
  position: absolute;
  padding: 0;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  background-image: url(../../images/close-button.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  -webkit-background-size: contain;        
  -moz-background-size: contain;          
  -o-background-size: contain;        
  cursor: pointer;
  transition: opacity .3s ease-in-out;
  outline: none;
}
.popup__close-button:hover {
  opacity: .7;
}
.popup__form {
  display: flex;
  flex-direction: column;
  width: 500px;
}
.popup__form_type_small {
  width: 400px;
}
.popup__form_type_medium {
  width: 600px;
}
.popup__form_type_large {
  width: 900px;
}
.popup__title {
  margin: 0px 0px 20px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: left;
  color: var(--title-color);
}
.popup__title_align_center {
  text-align: center;
}
.popup__subtitle {
  margin: 0px 0px 10px 0px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  max-width: 550px;
  overflow-x: hidden;
  color: var(--title-color);
}
.popup__icon {
  margin: 0px auto 20px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
}
.popup__form-line {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--white-color);
  border-radius: 100px;
  margin-bottom: 35px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  position: relative;
}
.popup__input {
  width: 100%;
  justify-content: space-between;
  padding: 15px 30px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.15px;
  color: var(--title-color);
  letter-spacing: 1.2px;
}
.popup__input::placeholder {
  color: var(--subtitle-color);
  font-size: .8em;
  letter-spacing: .5px;
}
.popup__submit {
  position: relative;
  margin: 0px auto 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup__submit-error {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15px;
  color: var(--third-light-color);
  transition: all .3s ease-in-out;
}
.popup__submit-error_type_show {
  opacity: 1;
}
.popup__submit-error_type_hide {
  opacity: 0;
}
.popup__submit-button {
  margin-top: 25px;
  max-width: 350px;
  background-color: var(--third-light-color);
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.4px;
  padding: 17.5px 77.5px;
  outline: none;
  transition: all .3s ease-in-out;
  position: relative;
  z-index: 3;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.popup__submit-button:hover {
  cursor: pointer;
  opacity: .7;
}
.popup__submit-button_type_block {
  pointer-events: none;
  user-select: none;
  background-color: var(--background-main-color);
  color: var(--background-third-color);
}
.popup__input-error {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  position: absolute;
  color: var(--third-light-color);
  top: calc(100% + 10px);
  left: 28px;
  opacity: 0;
  transition: .3s ease-in-out;
}
.popup__input-error_active {
  opacity: 1;
}
.popup__choose-file {
  margin: 20px 0px;
}
.popup__error-request {
  margin: 10px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--third-light-color);
  text-align: center;
  transition: .3s ease-in-out;
  max-width: 350px;
}
.popup__error-request_visible_show {
  opacity: 1;
}
.popup__error-request_visible_hide {
  opacity: 0;
}

.popup__choose-file {
  margin: 30px 0px 20px;
  width: 100%;
}
.popup__choose-file-form {
  margin: 0px;
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 25px 30px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.15px;
  color: var(--title-color);
  letter-spacing: 1.2px;
  background-color: var(--white-color);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  box-sizing: border-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}