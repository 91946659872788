.educational-material__table {
  width: 100%;
}
.educational-material__export-list {
  padding: 0px;
  margin: 40px 0px 20px 0px;
  list-style-type: none;
  display: flex;
}
.educational-material__export-item {
  margin: 0px 30px 0px 0px;
}
.educational-material__table-head {
  border-bottom: 1px solid var(--subtitle-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}
.educational-material__table-head-column-name {
  font-size: 24px;
  line-height: 1.3;
  font-weight: bold;
  margin: 0px;
  color: var(--title-color);
}
.educational-material__table-head-column-caption {
  font-size: 16px;
  line-height: 1.3;
  font-weight: normal;
  color: var(--subtitle-color);
}
.educational-material__table-head-column-caption_font_weight {
  font-weight: bold;
}
.educational-material__table-head-column-caption_type_dragging {
  color: var(--white-color);
}
.educational-material__table-body-row-name {
  font-size: 16px;
  line-height: 1.3;
  font-weight: normal;
  margin: 0px 20px 0px 0px;
  color: var(--subtitle-color);
}
.educational-material__table-body-row-name_type_themes {
  padding-left: 20px;
}
.educational-material__table-body-row-name_type_dragging {
  color: var(--white-color);
}
.table-head-column_type_name {
  width: 100%;
  padding: 5px 10px;
  border-right: 1px solid var(--subtitle-color);
}
.table-head-column_type_hours {
  padding: 5px 10px;
  flex-basis: 337px;
  flex-shrink: 0;
}
.educational-material__table-head-column-list {
  display: flex;
  align-items: center;
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}
.educational-material__table-body-row {
  padding: 0px 10px;
  margin: 5px 0px;
  border-right: 1px solid var(--subtitle-color);
}
.educational-material__table-body-row:last-of-type {
  border-right: none;
}
.educational-material__table-body-row_type_dragging {
  border-right: 1px solid var(--white-color);
}
.table-body-row_type_hours {
  flex-basis: 337px;
  flex-shrink: 0;
}
.table-body-row_type_name {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-body-row-name_type_title {
  font-size: 20px;
  line-height: 1.3;
  font-weight: bold;
  margin: 0px 20px 0px 0px;
  color: var(--title-color);
}
.educational-material__table-head-column-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.educational-material__table-head-column-item:last-of-type {
  margin: 0px;
}
.educational-material__table-body {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}
.educational-material__table-row {
  display: flex;
  flex-direction: column;
}
.educational-material__row-list {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
}