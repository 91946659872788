.accordion__section {
  display: flex;
  flex-direction: column;
}
.accordion__section_type_choose {
  margin: 20px 0px 0px 0px;
  width: 100%;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: var(--background-third-color);
  color: var(--title-color);
  cursor: pointer;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color .7s ease;
  border-radius: 10px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: var(--third-light-color);
  color: var(--white-color);
}

/* Style the accordion content title */
.accordion__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.15;
  z-index: 2;
}
.accordion__title_type_choose {
  text-align: left;
  max-width: 90%;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
  color: var(--white-color);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: var(--background-secondary-color);
  overflow: hidden;
  transition: max-height 0.6s ease;
  border-radius: 10px;
  z-index: 1;
}

/* Style the accordion content text */
.accordion__text {
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
  padding: 15px 15px;
}
.accordion__text_type_choose {
  margin: 0px 50px 0px 0px;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
  text-align: left;
}
.accordion__button {
  width: 100%;
  padding: 15px 15px;
  outline: none;
  border: none;
  background-color: var(--background-secondary-color);
  border: 1px solid var(--border-card-color);
  border-radius: 10px;
  display: flex;
  justify-content: space;
  align-items: center;
  transition: .3s ease-in-out;
}
.accordion__button:hover {
  cursor: pointer;
  background-color: var(--white-color);
}
.accordion__count {
  margin: 0px 0px 0px auto;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  border-radius: 50%;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  color: var(--white-color);
}
.accordion__img_type_choose {
  width: 25px;
  height: 25px;
  object-fit: contain;
  object-position: center;
  margin-right: 15px;
}

@media all and (max-width: 1000px) {
  .accordion__title {
    padding: 8px 0px;
    margin: 0px;
    font-size: 14px;
  }
  .accordion__text {
    font-size: 14px;
  }
}