.add-material__list {
  padding: 0px;
  margin: 10px 0px 0px 0px;
  list-style-type: none;
  width: 100%;
}
.add-material__item-input {
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 25px 0px;
  position: relative;
}
.add-material__input-name {
  margin: 0px 10px 5px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
}
.add-material__input {
  width: calc(100% - 40px);  
  justify-content: space-between;
  padding: 12px 22px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  color: var(--title-color);
  letter-spacing: 1.5px;
  margin: 0px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  position: relative;
}
.add-material__input-file {
  width: calc(100% - 60px);  
  display: inline-block;
  justify-content: space-between;
  padding: 12px 42px 12px 22px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.15;
  color: var(--title-color);
  letter-spacing: 1.5px;
  margin: 0px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  position: relative;
}
.add-material__input-file:hover {
  cursor: pointer;
}
.add-material__input-file:after {
  content: '';
  background-image: url('../../../../../images/upload-input.png');
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 18px;
  top: 7px;
  z-index: 25;
}
.add-material__input::placeholder {
  font-size: 14px;
  color: var(--subtitle-color);
}
.add-material__btn-save {
  margin: 0px auto 0px 0px;
}