.reference-information__list {
  list-style-type: none;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  width: 100%;
}
.reference-information__item {
  width: 100%;
  margin: 0px 0px 15px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  transition: .3s;
  position: relative;
}
.reference-information__item-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}
.reference-information__item-name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  color: var(--title-color);
}
.reference-information__item-description {
  margin: 5px 0px 0px 0px;
  font-size: 16px;
  line-height: 1.3;
  max-width: calc(100% - 40px - 80px - 20px);
  overflow-x: hidden;
}
.reference-information___buttons {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px auto;
  padding: 0px 20px 0px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  height: 100%;
  overflow-x: hidden;
  background-color: var(--main-color);
  transition: .7s ease-in-out;
  z-index: 100;
  cursor: auto;
  width: 30px;
}
.reference-information___buttons_type_show {
  width: 280px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.reference-information___btn {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 7px 12px;
  max-width: max-content;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.reference-information___btn:hover {
  opacity: .7;
  cursor: pointer;
}
.reference-information___btn-edit {
  background-color: var(--secondary-light-color);
  margin: 0px 7px 0px 0px;
}
.reference-information___btn-delete {
  background-color: var(--third-light-color);
}
.reference-information___btn-menu {
  margin: 0px 20px 0px 0px;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  position: relative;
  background-image: url('../../../../../images/menu.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  background-color: inherit;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.reference-information___btn-menu:hover {
  opacity: .7;
  cursor: pointer;
}
.reference-information___btn-menu_type_show {
  transform: rotate(90deg);
}
.reference-information__btn-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  background-image: url("../../../../../images/cancel.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  background-color: inherit;
  width: 25px;
  height: 25px;
}
.reference-information__btn-delete:hover {
  opacity: .7;
  cursor: pointer;
}