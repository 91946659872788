.additional-material {
  margin: 20px 0px 0px 0px;
  width: 100%;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.additional-material__title {
  margin: 0px 0px 10px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}
.additional-material__documents-list {
  margin: 0px;
  width: 100%;
  list-style-type: none;
  padding: 0px;
}
.additional-material__documents-item {
  margin: 0px auto 10px 0px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-card-color);
}
.additional-material__documents-item-count {
  margin: 0px 10px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 1.2px;
  color: var(--subtitle-color);
}
.additional-material__documents-link {
  margin: 0px 10px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  background-color: rgba(74, 86, 226, 0.1);
  color: var(--main-color);
  padding: 5px 10px;
  border-radius: 3px;
  letter-spacing: 1.2px;
  text-decoration: none;
  transition: .3s ease-in-out;
}
.additional-material__documents-link:hover {
  background-color: var(--main-color);
  color: var(--white-color);
  cursor: pointer;
}
.additional-material__btn-remove  {
  margin: 0px 0px 0px auto;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  position: relative;
  background-image: url('../../../../../images/cancel.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  background-color: inherit;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}
.additional-material__btn-remove:hover {
  opacity: .7;
  cursor: pointer;
}
.additional-material__documents-caption {
  margin: 0px auto 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
  color: var(--border-card-color);
  letter-spacing: 1.2px;
}
.additional-material_btn_add_material {
  max-width: 320px;
  margin: 30px 0px 0px 0px;
  background-color: var(--secondary-color);
}