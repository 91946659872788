.discussion__title {
  margin: 10px 0px 20px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
  color: var(--title-color);
}
.discussion__teams-link {  
  margin: 0px 0px 0px auto;
  color: var(--white-color);
  background-color: var(--main-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 10px 40px 10px 15px;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  text-decoration: none;
}
.discussion__teams-link:hover {
  opacity: .7;
}
.discussion__teams-link::after {
  content: '';
  background-image: url('../../images/arrows/arrow-right-white.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 11px;
  z-index: 5;
  transition: .3s ease-in-out;
}