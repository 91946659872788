.knowledge-item__btn_list {
  list-style-type: none;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  display: flex;
  align-items: center;
}
.knowledge-item__btn_item {
  margin: 0px 15px 0px 0px;
}
.knowledge-item__btn_item:last-of-type {
  margin: 0px;
}
.knowledge-item__btn {
  background-color: var(--main-color);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  margin: 0px;
  padding: 10px 40px 10px 10px;
  text-transform: none;
  text-decoration: none;
  max-width: max-content;
}
.knowledge-item__btn_export::after {
  content: '';
  background-image: url('../../../../../images/word-white.png');
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 7px;
  top: 8px;
  z-index: 5;
}
.knowledge-item__btn_down {
  background-color: var(--secondary-color);
}
.knowledge-item__btn_down::after {
  content: '';
  background-image: url('../../../../../images/sort-down.png');
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 7px;
  top: 8px;
  z-index: 5;
}
.knowledge-item__btn_up {
  background-color: var(--third-light-color);
}
.knowledge-item__btn_up::after {
  content: '';
  background-image: url('../../../../../images/sort-up.png');
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 7px;
  top: 8px;
  z-index: 5;
}
.knowledge-item__subtitle {
  margin: 30px 0px 0px 0px;
}
.knowledge-item__list {
  padding: 0px;
  margin: 20px 0px 0px 0px;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 30px;
  width: 100%;
}
.knowledge-item__item {
  margin: 0px 10px 30px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  width: 260px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .3s ease-in-out;
}
.knowledge-item__item:hover {
  border: 2px solid var(--main-color);
  transform: translateY(2px);
}
.knowledge-item__count {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 1.3;
  text-align: center;
  color: var(--title-color);
}
.knowledge-item__count_color_none {
  color: var(--third-light-color);
}
.knowledge-item__count_color_progress {
  color: var(--fourth-light-color);
}
.knowledge-item__count_color_success {
  color: var(--secondary-color);
}
.knowledge-item__name {
  margin: 10px 0px 10px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: var(--title-color);
}
.knowledge-item__btn-choose {
  margin: auto 0px 0px 0px;
  background-color: rgba(250, 117, 101, .1);
  color: var(--third-light-color);
  box-sizing: border-box;
  border: none;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.knowledge-item__item:hover .knowledge-item__btn-choose {
  cursor: pointer;
  background-color: var(--main-color);
  color: var(--white-color);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.knowledge-item__btn-choose:hover {
  transform: scale(1.05);
}

