#tree {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  border: 2px solid var(--border-card-color);
  border-radius: 10px;
}
#tree>svg {
  background-color: var(--background-third-color);
  width: 100%;
  height: 100%;
}
.field__name {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3;
  text-align: left;
  color: var(--white-color);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1000;
}
.field__tooltip {
  font-size: 18px;
  opacity: 0;
}
.field__name:hover .field__tooltip {
  opacity: 1;
}
.field__menu {
  width: 20px;
  height: 20px;
  cursor: pointer;
  object-position: center;
  object-fit: cover;
  transition: .3s ease-in-out;
}
.field__menu:hover {
  opacity: .7;
}
.field__warning {
  width: 22px;
  height: 22px;
  object-position: center;
  object-fit: cover;
}
.field__title {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
  color: var(--white-color);
}
.chart-menu div span {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: var(--subtitle-color);
  padding-left: 30px;
  position: relative;
}
.chart-menu div {
  padding: 15px 15px !important;
  cursor: pointer;
}
.chart-menu div span::before {
  content: '';
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="sort"] span::before {
  content: '';
  background-image: url('../../../../../images/sort.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="pdf"] span::before {
  content: '';
  background-image: url('../../../../../images/export-pdf.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="png"] span::before {
  content: '';
  background-image: url('../../../../../images/export-png.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="word"] span::before {
  content: '';
  background-image: url('../../../../../images/export-word.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="justification"] span::before {
  content: '';
  background-image: url('../../../../../images/export-archive.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="addAbility"] span::before {
  content: '';
  background-image: url('../../../../../images/add-color.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="addSkill"] span::before {
  content: '';
  background-image: url('../../../../../images/add-color.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="addKnowledge"] span::before {
  content: '';
  background-image: url('../../../../../images/add-color.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="remove"] span::before {
  content: '';
  background-image: url('../../../../../images/delete-color.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="edit"] span::before {
  content: '';
  background-image: url('../../../../../images/edit-color.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="disconnect"] span::before {
  content: '';
  background-image: url('../../../../../images/disconnect-color.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="addLink"] span::before {
  content: '';
  background-image: url('../../../../../images/add-connection-color.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="removeLink"] span::before {
  content: '';
  background-image: url('../../../../../images/remove-connection-color.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.chart-menu div[data-item ="order"] span::before {
  content: '';
  background-image: url('../../../../../images/swap.png');
  position: absolute;
  left: 0px;
  top: -2px;
  z-index: 10000;
}
.zoon-chart__btn-sort:hover {
  opacity: .7;
  cursor: pointer;
}
.zoon-chart__btn-control {
  margin: 30px 0px 0px 0px;
  display: flex;
}
.zoon-chart__btn_type_add-skill {
  margin-right: 20px;
  background-color: var(--secondary-color);
}
.zoon-chart__btn_type_add-ability {
  margin-right: 20px;
  background-color: var(--main-color);
}
.zoon-chart__btn_type_import-knowledge {
  background-color: var(--third-light-color);
}
.zoon-chart__btn_type_build-competence {
  margin: 0px 0px 0px auto;
  background-color: #8B00FF;
}
.zoon-chart__btn_type_build-competence::after {
  content: '';
  background-image: url('../../../../../images/build-competence.png');
  background-size: 28px;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 5;
  transition: .3s ease-in-out;
}
