.zoon-typology {
  min-height: 80vh;
}
.zoon-typology__title {
  margin: 50px 0px 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}
.zoon-typology__list {
  list-style: none;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px;
}
.zoon-typology__item {
  box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  margin: 0px 0px 15px 0px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: left;
  position: relative;
}
.zoon-typology__item-info {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
.zoon-typology__item-count {
  margin: 0px 20px 0px 0px;
  color: var(--border-card-color);
  font-weight: 400;
  font-size: 20px;
}
.zoon-typology__name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
  width: 100%;
}
.zoon-typology__knowledge-list {
  list-style-type: none;
  margin: 0px;
  padding: 0px 0px 0px 35px;
}
.zoon-typology__knowledge-item {
  display: flex;
  align-items: center;
  padding: 5px 0px;
}
.zoon-typology__knowledge-symbol {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 0px 15px 0px 15px;
  background-color: var(--third-light-color);
}
.zoon-typology__knowledge-name {
  margin: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}