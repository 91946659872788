.main-page {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}
.main-page__version {
  margin: auto 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
}
.main-page__container {
  margin: 50px 0px 0px 0px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}
.main-page__news {
  grid-area: 1 / 1 / 3 / 4;
}
.main-page__support {
  grid-area: 1 / 4 / 2 / 6;
}
.main-page__feedback {
  grid-area: 2 / 4 / 3 / 6;
}
.main-page__section {
  width: 100%;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  padding: 20px;
  min-height: 250px;
}
.main-page__section-title {
  position: relative;
  margin: 0px 0px 10px 0px;
  padding: 10px 0px 0px 55px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}
.main-page__title-news::before {
  content: '';
  background-image: url('../../images/news.png');
  background-size: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
  left:0px;
  top: 0px;
  z-index: 5;
}
.main-page__title-support::before {
  content: '';
  background-image: url('../../images/support.png');
  background-size: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
  left:0px;
  top: 0px;
  z-index: 5;
}
.main-page__title-feedback::before {
  content: '';
  background-image: url('../../images/feedback.png');
  background-size: 40px;
  width: 40px;
  height: 40px;
  position: absolute;
  left:0px;
  top: 0px;
  z-index: 5;
}
.main-page__section-subtitle {
  margin: 10px auto 0px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
}
.main-page__section-news-caption {
  margin: 15px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
  color: var(--border-card-color);
  letter-spacing: 1.2px;
}
.main-page__section-feedback-message {
  margin: 0px 0px 0px 10px;
  color: var(--secondary-color);
}
.main-page__section-news-list {
  margin: 12px 0px 0px 0px;
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-page__section-news-item {
  margin: 0px 0px 16px 0px;
  padding: 16px 0px;
  border-bottom: 1px solid var(--border-card-color);
  width: 100%;
  display: flex;
  align-items: center;
}
.main-page__section-news-item:last-of-type {
  border-bottom: none;
}
.main-page__section-news-count {
  margin: 0px 30px 0px 10px;
  color: var(--border-card-color);
  font-weight: 400;
  font-size: 18px;
}
.main-page__section-news-text {
  margin: 0px;
  font-style: normal;
  font-size: 16px;
  line-height: 1.3;
  color: var(--subtitle-color);
}