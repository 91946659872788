.homepage-capabilities {
  width: 100%;
  margin: 0px auto;
  padding: 50px 0px 40px 0px;
  background-color: var(--background-third-color);
  position: relative;
}
.homepage-capabilities__list {
  width: 100%;
  margin: 30px 0px 0px 0px;
  padding: 40px 0px;
  box-sizing: border-box;
  border-radius: 10px;
  border-top: 3px solid var(--main-color);
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white-color);
  box-shadow: 0 0 32px rgb(0 0 0 / 8%);
}
.homepage-capabilities__item {
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--background-third-color);
}
.homepage-capabilities__item:last-of-type {
  border-right: none;
}
.homepage-capabilities__item-img {
  margin: 0px;
  width: 60px;
  height: 60px;
}
.homepage-capabilities__item-title {
  margin: 22px 0px 0px 0px;
  max-width: 200px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--title-color);
}
.homepage-capabilities__item-subtitle {
  margin: 8px 0px 0px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: var(--subtitle-color);
}