.control-program__title {
  margin: 20px 0px 0px 0px;
  font-size: 24px;
  line-height: 1.3;
}
.control-program__subtitle {
  margin: 10px 0px 0px 0px;
  font-size: 18px;
  line-height: 1.3;
}
.control-program__btn-add {
  margin: 20px 0px 0px 0px;
}
.control-program__add {
  margin: 30px 0px 20px;
}
.control-program__add-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: hidden;
  transition: all .3s ease-in-out;
}
.control-program__add-menu_type_show {
  height: 96px;
}
.control-program__add-menu_type_hide {
  height: 0px;
}
.control-program__menu-btn {
  background-color: var(--main-color);
  border-radius: 0%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1.2px;
  padding: 15px;
  width: 228px;
  outline: none;
  transition: .3s ease-in-out;
}
.control-program__add-btn {
  min-width: 228px;
}
.control-program__add-btn_type_show {
  border-radius: 0%;
}
.control-program__add-btn_type_show::after {
  transform: rotate(180deg);
}
.control-program__add-btn_type_hide::after {
  transform: rotate(0deg);
}
.control-program__list {
  list-style-type: none;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  width: 100%;
}
.control-program__item {
  margin: 0px 0px 15px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  transition: .3s;
  position: relative;
}
.control-program__item-count {
  margin: 0px 30px 0px 10px;
  color: var(--border-card-color);
  font-weight: 400;
  font-size: 18px;
}
.control-program__item-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: calc(100% - 200px);
}
.control-program__item-name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  color: var(--title-color);
}
.control-program__status {
  background-image: url('../../../images/archive.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 35px;
  height: 35px;
  position: relative;
  margin: 0px 70px 0px auto;
}
.control-program__item___buttons {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px auto;
  padding: 0px 20px 0px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  height: 100%;
  overflow-x: hidden;
  background-color: var(--main-color);
  transition: .7s ease-in-out;
  z-index: 100;
  cursor: auto;
  width: 30px;
}
.control-program__item___buttons_type_show {
  width: 280px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.control-program__item___btn {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 7px 12px;
  max-width: max-content;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.control-program__item___btn:hover {
  opacity: .7;
  cursor: pointer;
}
.control-program__item___btn-edit {
  background-color: var(--secondary-light-color);
  margin: 0px 7px 0px 0px;
}
.control-program__item___btn-delete {
  background-color: var(--third-light-color);
}
.control-program__item___btn-menu {
  margin: 0px 20px 0px 0px;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  position: relative;
  background-image: url('../../../images/menu.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  background-color: inherit;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.control-program__item___btn-menu:hover {
  opacity: .7;
  cursor: pointer;
}
.control-program__item___btn-menu_type_show {
  transform: rotate(90deg);
}
.control-program__contacts {
  margin: 10px 0px 0px 0px;
  display: flex;
}
.control-program__stage {
  margin: 0px 25px 0px 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.15;
  padding-left: 30px;
  position: relative;
  color: var(--main-color);
}
.control-program__stage::before {
  content: '';
  background-image: url('../../../images/status.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: -1px;
  z-index: 5;
}
.control-program__hours {
  margin: 0px 25px 0px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.15;
  padding-left: 30px;
  position: relative;
  color: var(--main-color);
}
.control-program__hours::before {
  content: '';
  background-image: url('../../../images/hours.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: -1px;
  z-index: 5;
}
.control-program__participants {
  margin: 0px 25px 0px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.15;
  padding-left: 30px;
  position: relative;
  color: var(--main-color);
}
.control-program__participants::before {
  content: '';
  background-image: url('../../../images/role.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: -1px;
  z-index: 5;
}
.control-program__submit-error {
  margin: 20px 0px;
}
.control-program__participant-caption{
  margin: 5px 10px 3px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
  text-align: left;
  color: var(--border-card-color);
  letter-spacing: 1.2px;
}
.control-program__popup-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.control-program__participant-btn-add {
  margin: 20px 0px 0px 0px;
}
.control-program__participant-list {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
}
.control-program__participant-name {
  margin: 0px 15px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
}
.control-program__participant-bth-delete {
  margin: 0px 0px 0px auto;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  background-image: url('../../../images/cancel.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  background-color: inherit;
  width: 25px;
  height: 25px;
}
.control-program__participant-bth-delete:hover {
  opacity: .7;
  cursor: pointer;
}
