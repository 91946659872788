.initial-data__list {
  margin: 30px 0px 0px 0px;
  list-style-type: none;
  padding: 0px;
  display: grid;
  gap: 20px;
  grid-auto-rows: min-content;
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
}
.initial-data__item {
  margin: 0px;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  display: flex;
  flex-direction: column;
  transition: .3s ease-in-out;
  position: relative;
}
.initial-data__item_type_direction {
  grid-column: col / span 3;
}
.initial-data__item_type_basis {
  grid-column: col 4 / span 4;
}
.initial-data__item_type_requirements {
  grid-column: col / span 3;
}
.initial-data__item_type_target {
  grid-column: col 4 / span 3;
}
.initial-data__item_type_form {
  grid-column: col / span 2;
}
.initial-data__item_type_hours {
  grid-column: col 3 / span 2;
}
.initial-data__item_type_description-small {
  grid-column: col 5 / span 2;
}
.initial-data__item_type_description {
  grid-column: col / span 6;
}
.initial-data__item_type_structure {
  grid-column: col / span 6;
}
.initial-data__item_type_info {
  grid-column: col / span 6;
}
.initial-data__item_type_qualification-req {
  grid-column: col / span 2;
}
.initial-data__item_type_qualification-area {
  grid-column: col 3 / span 2;
}
.initial-data__item_type_qualification-obj {
  grid-column: col 5 / span 2;
}

.initial-data__item-name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
  color: var(--title-color);
}
.initial-data__item-title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: var(--title-color);
}
.initial-data__item-title_margin_top {
  margin-top: 20px;
}
.initial-data__item-title_margin_bottom {
  margin-bottom: 10px;
}
.initial-data__item-subtitle {
  margin: 10px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: var(--subtitle-color);
}
.initial-data__basis-list {
  margin: 30px auto 0px;
  max-width: 1280px;
  width: 100%;
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.initial-data__basis-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  min-width: 300px;
  margin: 0px 10px;
}
.initial-data__basis-item:last-of-type {
  margin-bottom: 0px;
}
.initial-data__basis-img {
  width: 75px;
  height: 75px;
  object-position: center;
  object-fit: contain;
  margin: 0px;
}
.initial-data__item-subtitle_type_basis {
  margin: 10px 0px 0px 0px;
  text-align: center;
}
.initial-data__item-subtitle_type_info {
  text-align: center;
  margin: 10px auto 0px;
  max-width: 700px;
}
.initial-data__item-subtitle_type_structure {
  text-align: center;
  margin: 10px auto 0px;
  max-width: 900px;
}
.initial-data__basis-name {
  margin: 10px 0px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  max-width: 160px;
}
.btn_type_basis {
  margin: 5px 0px 0px 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 1.2px;
  padding: 5px 22.5px 5px 8.5px;
  max-width: max-content;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
  background-color: var(--secondary-color);
}
.btn_type_basis:hover {
  opacity: .7;
  cursor: pointer;
}
.btn_type_basis::before {
  content: '';
  background-image: url('../../../../images/add.png');
  background-size: 10px;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 7px;
  top: 9px;
  z-index: 5;
}
.initial-data__basis-el-list {
  list-style-type: none;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-direction: column;
}
.initial-data__basis-el {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  max-width: 200px;
}
.initial-data__basis-el:last-of-type {
  margin-bottom: 0px;
}
.initial-data__basis-el-name {
  margin: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}
.initial-data__basis-el-code {
  margin: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}
.initial-data__item-requirements-list {
  margin: 0px auto 0px 0px;
  padding: 0px;
  list-style-type: none;
}
.initial-data__item-qualification-text {
  resize: none;
  width: calc(100% - 20px);
  min-height: 160px;
  padding: 10px;
  margin: 10px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
  border: 2px solid var(--border-card-color);
  border-radius: 3px;
  transition: .3s ease-in-out;
}
.initial-data__item-qualification-text:focus {
  border: 2px solid var(--main-color);
  outline: none;
}
.initial-data__item-qualification-text::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--border-card-color);
}
.initial-data__item-target-tasks {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  position: relative;
}
.initial-data__item-target-task {
  margin: 15px auto 0px 0px;
  padding-left: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
  position: relative;
}
.target-task_type_first::before {
  content: '';
  background-image: url('../../../../images/knowledge.png');
  background-size: 28px;
  width: 28px;
  height: 28px;
  position: absolute;
  left: 2px;
  top: 3px;
}
.target-task_type_second::before {
  content: '';
  background-image: url('../../../../images/achievement.png');
  background-size: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 2px;
  top: -2px;
}
.initial-data__item-target-list {
  margin: 0px auto 0px 0px;
  padding: 0px;
  list-style-type: none;
}
.initial-data__target-info {
  display: flex;
  flex-direction: row;
  margin: 20px 0px 30px 0px;
  padding-left: 50px;
  width: calc(100% - 60px);
  min-height: 40px;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.initial-data__target-title {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
}
.target-info_type_first:before {
  content: '';
  background-image: url('../../../../images/target-achievement.png');
  background-size: 35px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 2px;
  top: 0px;
  z-index: 5;
}
.target-info_type_second:before {
  content: '';
  background-image: url('../../../../images/target-diplom.png');
  background-size: 35px;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 2px;
  top: 5px;
  z-index: 5;
}
.initial-data__btn-change {
  background-color: var(--main-color);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  margin: 0px;
  padding: 10px 10px 10px 40px;
  text-transform: none;
  text-decoration: none;
  max-width: max-content;
}
.initial-data__btn-change::before {
  content: '';
  background-image: url('../../../../images/build-competence.png');
  background-size: 25px;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  position: absolute;
  left: 7px;
  top: 8px;
  z-index: 5;
}
.initial-data__buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px auto 20px 0px;
  position: relative;
  width: 100%;
}
.initial-data__buttons-message {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  top: 55px;
  left: 3px;
  transition: .3s ease-in-out;
  z-index: 100;
  opacity: 1;
}
.initial-data__buttons-message_type_hide {
  opacity: 1;
}
.initial-data__buttons-message_type_show {
  opacity: 1;
}
.initial-data__buttons-message_type_error {
  color: var(--third-light-color);
}
.initial-data__buttons-message_type_success {
  color: var(--secondary-color);
}
.initial-popup__title {
  margin: 0px 0px 25px 0px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  max-width: 550px;
  color: var(--title-color);
}
.initial-popup__control {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.initial-popup__show {
  width: 100%;
  background-color: var(--main-color);
  transition: all ease-in-out .3s;
}
.initial-popup__show_type_show {
  height: 213px;
}
.initial-popup__show_type_hide {
  height: 0px;
}
.initial-popup__btn-add {
  margin: 0px;
}
.initial-popup__btn-add_type_show {
  border-radius: 0px;
}
.initial-popup__btn-add_type_hide {
  border-radius: 5px;
}
.initial-popup__btn-add_type_hide::after {
  transform: rotate(0deg);
}
.initial-popup__btn-add_type_show::after {
  transform: rotate(180deg);
}
.initial-popup__search {
  display: flex;
}
.initial-popup__search-input {
  margin: 0px 0px 0px 20px;
}
.initial-popup__list {
  margin: 20px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.initial-popup__tags-profstandart {
  display: flex;
  align-items: center;
}
.initial-popup__link {
  text-decoration: none;
  margin: 0px 0px 0px 15px;
  font-size: 14px;
  line-height: 1.3;
  color: var(--border-card-color);
  transition: .3s ease-in-out;
}
.initial-popup__link:hover {
  color: var(--main-color);
}
.initial-popup__list_type_fgoses {
  max-height: 45vh;
}
.initial-popup__item {
  margin: 0px 0px 15px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  transition: .3s;
  position: relative;
}
.initial-popup__item:hover {
  border: 2px solid var(--main-color);
}
.initial-popup__checkbox {
  margin: 0px 0px 15px 0px;
}
.initial-popup__info {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 20px;
  max-width: 700px;
}
.initial-popup__name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  color: var(--title-color);
  max-width: 650px;
}
.initial-popup__code {
  margin: 0px 0px 5px 0px;
  padding: 2px 8px;
  color: var(--white-color);
  border-radius: 3px;
  letter-spacing: 1.2px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: -webkit-max-content;
  width: max-content;
  background-color: var(--secondary-color);
}
.initial-popup__order {
  margin: 5px 0px 0px 0px;
  font-size: 14px;
  line-height: 1.3;
}
.initial-popup__btn-save {
  margin: 20px auto 0px 0px;
}
.initial-popup___buttons {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px auto;
  padding: 0px 20px 0px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  height: 100%;
  overflow-x: hidden;
  background-color: var(--main-color);
  transition: .7s ease-in-out;
  z-index: 100;
  cursor: auto;
  width: 30px;
}
.initial-popup___buttons_type_show {
  width: 280px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.initial-popup___btn {
  margin: 0px;
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 7px 12px;
  max-width: max-content;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.initial-popup___btn:hover {
  opacity: .7;
  cursor: pointer;
}
.initial-popup___btn-edit {
  background-color: var(--secondary-light-color);
  margin: 0px 7px 0px 0px;
}
.initial-popup___btn-delete {
  background-color: var(--third-light-color);
}
.initial-popup___btn-menu {
  margin: 0px 20px 0px 0px;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  position: relative;
  background-image: url('../../../../images/menu.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  background-color: inherit;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.initial-popup___btn-menu:hover {
  opacity: .7;
  cursor: pointer;
}
.initial-popup___btn-menu_type_show {
  transform: rotate(90deg);
}
.initial-popup__list-input {
  padding: 0px;
  margin: 10px 0px 0px 0px;
  list-style-type: none;
  width: 100%;
  transition: .3s ease-in-out;
}
.list-input_type_show {
  margin: 0px;
  display: block;
}
.list-input_type_hide {
  margin: 0px;
  display: none;
}
.initial-popup__item-input {
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 25px 0px;
  position: relative;
}
.initial-popup__item-input:last-of-type {
  padding: 0px 0px 25px 0px;
}
.initial-popup__item-input_margin_bottom {
  margin: 0px;
  padding: 0px;
}
.initial-popup__item-select {
  justify-content: space-between;
  
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  color: var(--title-color);
  letter-spacing: 1.5px;
  margin: 0px;
  width: calc(100% - 20px);
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  position: relative;
}
.initial-popup__input-name {
  margin: 0px 10px 3px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
}
.initial-popup__input {
  width: calc(100% - 60px);
  justify-content: space-between;
  padding: 12px 22px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  color: var(--title-color);
  letter-spacing: 1.5px;
  margin: 0px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.initial-popup__input::placeholder {
  font-size: 14px;
}
.initial-popup__result-name {
  font-size: 16px;
  line-height: 1.3;
  margin: 0px auto 5px 10px;
  max-width: 884px;
  overflow-x: hidden;
}
.initial-popup__result-name_weight_bold {
  font-weight: bold;
}
.initial-popup__input-error {
  position: absolute;
  bottom: 0px;
  left: 20px;
  color: red;
  font-size: 14px;
  line-height: 1.3;
  transition: opacity ease-in-out .3s;
  opacity: 0;
}
.initial-popup__input-error_type_show {
  opacity: 1;
}
.initial-data__documents-list {
  margin: 20px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.initial-data__documents-item {
  margin: 0px 0px 15px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  transition: .3s;
  position: relative;
}
.initial-data__documents-item:last-of-type {
  margin: 0px;
}
.initial-data__documents-img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
  margin-right: 20px;
}
.initial-data__documents-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}
.initial-data__documents-tags {
  display: flex;
  margin: 0px 0px 10px 0px;
}
.initial-data__documents-type {
  margin: 0px 15px 0px 0px;
  padding: 2px 8px;
  color: var(--white-color);
  border-radius: 3px;
  letter-spacing: 1.2px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: max-content;
  background-color: var(--third-light-color);
}
.initial-data__documents-code {
  margin: 0px 15px 0px 0px;
  padding: 2px 8px;
  color: var(--white-color);
  border-radius: 3px;
  letter-spacing: 1.2px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: max-content;
  background-color: var(--secondary-color);
}
.initial-data__documents-name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
}
.initial-data__documents-order {
  margin: 5px 0px 0px 0px;
  font-size: 14px;
  line-height: 1.3;
}
.initial-data__documents-delete-btn {
  margin: 0px 0px 0px auto;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  position: relative;
  background-image: url('../../../../images/cancel.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
  background-color: inherit;
  width: 25px;
  height: 25px;
  flex-shrink: 0;
}
.initial-data__documents-delete-btn:hover {
  cursor: pointer;
  opacity: .7;
}
.initial-data__btn_type_add-nsi {
  margin: 15px auto 0px;
  max-width: max-content;
}
.initial-data__target-input {
  margin: 15px 0px;
}