.development__list {
  padding: 0px;
  margin: 30px 0px 0px 0px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.development__item {
  margin-bottom: 40px;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  padding: 40px 40px;
  margin: 0px 5px 20px 5px;
  display: flex;
  flex-direction: column;
  transition: .3s ease-in-out;
}
.development__item-info {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.development__item-title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.25;
  text-align: left;
  color: var(--title-color);
}
.development__item-description {
  padding: 0px;
  list-style-type: none;
  margin: 15px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
}
.development__item-caption {
  margin: 0px 50px 15px 0px;
  display: flex;
  align-items: center;
}
.development__item-caption:last-of-type {
  margin: 0px 0px 15px 0px;
}
.development__item-caption-img {
  width: 30px;
  height: 30px;
  object-position: center;
  object-fit: contain;
  margin: 0px 10px 0px 0px;
  user-select: none;
  pointer-events: none;
}
.development__item-caption-container {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.development__item-caption-title {
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.15;
  color: var(--main-color);
}
.development__item-caption-subtitle {
  margin: 5px 0px 0px 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.15;
  color: var(--main-color);
}
.development__item-control {
  margin: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}
.development__item-button-performer {
  margin: 0px;
  background-color: var(--third-light-color);
  color: var(--white-color);
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.5px;
  padding: 6.5px 45px 6px 15px;
  max-width: max-content;
  outline: none;
  transition: .3s ease-in-out;
  position: relative;
}
.development__item-button-performer::after {
  content: '';
  background-image: url('../../images/arrows/arrow-right-white.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 11px;
  z-index: 5;
  transition: .3s ease-in-out;
}
.button-performer_type_show::after {
  transform: rotate(90deg);
}
.button-performer_type_hide::after {
  transform: rotate(0deg);
}
.development__item-button-performer:hover {
  cursor: pointer;
  opacity: .7;
}
.development__performers-list {
  padding: 0px;
  margin: 20px 0px 0px 0px;
  list-style-type: none;
  transition: .3s ease-in-out;
}
.performers-list_type_show {
  height: auto;
  overflow-y: visible;
  transition: .3s ease-in-out;
}
.performers-list_type_hide {
  height: 0px;
  overflow-y: hidden;
}
.development__performers-item {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-top: 1px solid var(--border-card-color);
}
.development__performers-img {
  margin: 0px 15px 0px 0px;
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
}
.development__performers-info {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.development__performers-name {
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.15;
  color: var(--title-color);
}
.development__performers-role {
  margin: 5px 0px 0px 0px;
  padding: 7px 15px;
  color: var(--white-color);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.2px;
  max-width: min-content;
  pointer-events: none;
  user-select: none;
}
.performers-role_type_methodist {
  background-color: var(--main-color);
}
.performers-role_type_expert {
  background-color: var(--third-light-color);
}
.performers-role_type_validator {
  background-color: var(--five-light-color);
}
.performers-role_type_supervisor {
  background-color: var(--fourth-light-color);
}
.performers-role_type_observer {
  background-color: var(--secondary-color);
}
.development__performers-contacts {
  margin: 0px 0px 0px auto;
  display: flex;
  flex-direction: column;
}
.development__performers-mail {
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.15;
  padding-left: 15px;
  position: relative;
  color: var(--main-color);
}
.development__performers-mail::before {
  content: '';
  background-image: url('../../images/mail.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -17px;
  top: -1px;
  z-index: 5;
}
.development__performers-phone {
  margin: 15px 0px 0px 0px;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.15;
  padding-left: 15px;
  position: relative;
  color: var(--main-color);
}
.development__performers-phone::before {
  content: '';
  background-image: url('../../images/phone.png');
  background-size: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -17px;
  top: 0px;
  z-index: 5;
}

.ReactCollapse--collapse {
  transition: height 700ms;
}

@media all and (max-width: 967px) {
  .development__list {
    margin: 20px 0px 0px 0px;
  }
  .development__item {
    margin-bottom: 40px;
    padding: 20px;
    margin: 0px 0px 20px 0px;
  }
  .development__item-title {
    font-size: 20px;
  }
  .development__item-button-performer {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.2px;
    padding: 10px 35px 10px 10px;
  }
  .development__item-button-performer::after {
    background-size: 15px;
    width: 15px;
    height: 15px;
    right: 12px;
    top: 13px;
  }
  .development__item-caption {
    margin: 0px 30px 15px 0px;
  }
  .development__performers-name {
    font-size: 16px;
  }
  .development__performers-role {
    padding: 5px 10px;
    font-size: 14px;
  }
  .development__performers-img {
    margin: 0px 15px 0px 0px;
    width: 50px;
    height: 50px;
  }
  .development__performers-mail {
    font-size: 14px;
    padding-left: 10px;
  }
  .development__performers-mail::before {
    background-size: 16px;
    width: 16px;
    height: 16px;
    left: -15px;
    top: 0px;
  }
  .development__performers-phone {
    font-size: 14px;
    padding-left: 10px;
  }
  .development__performers-phone::before {
    background-size: 16px;
    width: 16px;
    height: 16px;
    left: -15px;
    top: 1px;
  }
}

@media all and (max-width: 760px) {
  .development__item-title {
    font-size: 16px;
  }
  .development__item-caption-title {
    font-size: 14px;
  }
  .development__item-caption-subtitle {
    font-size: 14px;
  }
  .development__item-caption {
    margin: 0px 0px 15px 0px;
  }
  .development__item-control {
    flex-direction: column-reverse;
  }
  .development__item-description {
    flex-direction: column;
  }
  .development__item-button-performer {
    margin: 10px 0px 0px 0px;
    max-width: 100%;
  }
  .development__performers-item {
    flex-wrap: wrap;
  }
  .development__performers-info {
    max-width: 225px;
    margin: 0px 25px 0px 0px;
  }
  .development__performers-contacts {
    margin: 0px 0px 0px auto;
    min-width: 175px;
  }
}

@media all and (max-width: 560px) {
  .development__performers-contacts {
    margin: 10px 0px 0px 20px;
  }
  .development__performers-info {
      margin: 0px;
  }
}

@media all and (max-width: 360px) {
  .development__performers-item {
    flex-direction: column;
  }
  .development__performers-info {
    margin: 10px 0px;
    align-items: center;
  }
  .development__performers-name {
    text-align: center;
  }
  .development__performers-contacts {
    margin: 0px 0px 0px 20px;
    min-width: 0px;
  }
}