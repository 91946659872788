.open-answer {
  margin: 0px 0px 15px 0px;
  display: flex;
  align-items: center;
}
.open-answer__count {
  margin: 0px 5px 0px 5px;
}
.open-answer__input {
  margin: 0px 0px 0px 10px;
  background-color: #fff;
  border-radius: 100px;
  padding: 10px 20px 10px 20px;
  width: calc(100% - 120px);
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.15px;
  color: var(--title-color);
  border: 2px solid var(--border-card-color);
}
.open-answer__textarea {
  resize: none;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 0px 0px 0px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
  border: 2px solid var(--border-card-color);
  border-radius: 3px;
}
.open-answer__textarea:focus {
  border: 2px solid var(--main-color);
  outline: none;
}
.open-answer__textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--border-card-color);
}