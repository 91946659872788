.draggable-item {
  box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  margin: 0px 0px 15px 0px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.draggable-item_type_dragging {
  border: 1px solid var(--main-color);
  background-color: var(--main-color);
  color: #fff;
}
.draggable-item__count {
  margin: 0px 0px 0px 0px;
  color: var(--border-card-color);
  font-weight: 400;
  font-size: 18px;
  padding: 20px 30px;
}
.draggable-item__text {
  margin: 0px;
  padding: 20px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
  max-width: calc(100% - 170px);
  transition: max-width .7s ease-in-out;
}
.draggable-item_type_dragging .draggable-item__text {
  color: var(--white-color);
}