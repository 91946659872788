@font-face {
	font-family: 'Raleway';
	src: url('./Raleway/Raleway-Regular.eot'),
		url('./Raleway/Raleway-Regular.woff') format('woff'),
		url('./Raleway/Raleway-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('./Raleway/Raleway-Light.eot'),
		url('./Raleway/Raleway-Light.woff') format('woff'),
		url('./Raleway/Raleway-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('./Raleway/Raleway-Thin.eot'),
		url('./Raleway/Raleway-Thin.woff') format('woff'),
		url('./Raleway/Raleway-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('./Raleway/Raleway-SemiBold.eot'),
		url('./Raleway/Raleway-SemiBold.woff') format('woff'),
		url('./Raleway/Raleway-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('./Raleway/Raleway-ExtraLight.eot'),
		url('./Raleway/Raleway-ExtraLight.woff') format('woff'),
		url('./Raleway/Raleway-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('./Raleway/Raleway-Bold.eot'),
		url('./Raleway/Raleway-Bold.woff') format('woff'),
		url('./Raleway/Raleway-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('./Raleway/Raleway-Medium.eot'),
		url('./Raleway/Raleway-Medium.woff') format('woff'),
		url('./Raleway/Raleway-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('./Raleway/Raleway-ExtraBold.eot'),
		url('./Raleway/Raleway-ExtraBold.woff') format('woff'),
		url('./Raleway/Raleway-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Raleway';
	src: url('./Raleway/Raleway-Black.eot'),
		url('./Raleway/Raleway-Black.woff') format('woff'),
		url('./Raleway/Raleway-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
