.dpp__title {
  text-align: center;
  font-size: 40px;
  line-height: 1.3;
}

@media all and (max-width: 1367px) {
  .dpp__title {
    font-size: 36px;
  }
}

@media all and (max-width: 967px) {
  .dpp__title {
    font-size: 24px;
  }
}

@media all and (max-width: 760px) {
  .dpp__title {
    font-size: 20px;
  }
}