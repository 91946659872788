.nsi-popup__list {
  list-style-type: none;
  padding: 0px;
  margin: 10px 0px 0px 0px;
  width: 100%;
}
.nsi-popup__item {
  margin: 0px 0px 15px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  transition: .3s;
  position: relative;
}
.nsi-popup__item-info {
  display: flex;
  flex-direction: column;
  margin: 0px;
  width: 100%;
}
.nsi-popup__item-name {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
  color: var(--title-color);
  max-width: 630px;
}
.nsi-popup__item-description {
  margin: 5px 0px 0px 0px;
  font-size: 16px;
  line-height: 1.3;
  max-width: 630px;
}
.nsi-popup__item-btn {
  margin: 0px 0px 0px auto;
  box-sizing: border-box;
  border: none;
  outline: none;
  transition: .7s ease-in-out;
  position: relative;
  background-color: var(--main-color);
  color: var(--white-color);
  width: max-content;
  border-radius: 3px;
  letter-spacing: 1.2px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 12px;
}
.nsi-popup__item-btn:hover {
  cursor: pointer;
  opacity: .7;
}
.nsi-popup__title {
  margin: 0px 0px 25px 0px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  max-width: 550px;
  color: var(--title-color);
}
.nsi-popup__title_with_sub {
  margin: 0px 0px 8px 0px;
}
.nsi-popup__subtitle {
  margin: 0px 0px 25px 0px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
  max-width: 550px;
  color: var(--subtitle-color);
}
.nsi-popup__list-input {
  padding: 0px;
  margin: 10px 0px 0px 0px;
  list-style-type: none;
  width: 100%;
}
.nsi-popup__item-input {
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 25px 0px;
  position: relative;
}
.nsi-popup__item-input:last-of-type {
  padding: 0px 0px 25px 0px;
}
.nsi-popup__item-input_margin_bottom {
  margin: 0px;
  padding: 0px;
}
.nsi-popup__input-name {
  margin: 0px 10px 3px 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
}
.nsi-popup__input {
  width: calc(100% - 60px);
  justify-content: space-between;
  padding: 12px 22px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.15;
  color: var(--title-color);
  letter-spacing: 1.5px;
  margin: 0px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}
.nsi-popup__input::placeholder {
  font-size: 14px;
}
.nsi-popup__result-name {
  font-size: 16px;
  line-height: 1.3;
  margin: 0px auto 5px 10px;
}
.nsi-popup__result-name_weight_bold {
  font-weight: bold;
}
.nsi-popup__input-error {
  position: absolute;
  bottom: 0px;
  left: 20px;
  color: red;
  font-size: 14px;
  line-height: 1.3;
  transition: opacity ease-in-out .3s;
  opacity: 0;
}
.nsi-popup__input-error_type_show {
  opacity: 1;
}
.nsi-popup__btn-save {
  margin: 20px auto 0px 0px;
}
.nsi__search {
  margin: 30px 0px;
  width: 100%;
}