.homepage-аdvantages {
  width: 100%;
  margin: 0px auto;
  padding: 80px 0px 0px 0px;
  background-color: var(--background-third-color);
  position: relative;
}
.homepage-аdvantages__container {
  width: 100%;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.homepage-аdvantages__img {
  margin: 0px 30px;
  width: 480px;
  height: 480px;
  background-image: url('../../../images/main-page/main-page-adv.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  z-index: 2;
}
.homepage-аdvantages__list {
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
.homepage-аdvantages__item {
  margin: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;
}
.homepage-аdvantages__item-img {
  margin: 0px;
  width: 40px;
  height: 40px;
}
.homepage-аdvantages__item-title {
  margin: 12px 0px 0px 0px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--title-color);
}
.homepage-аdvantages__item-text {
  margin: 4px 0px 0px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--subtitle-color);
}