.export-program {
  display: flex;
  flex-direction: column;
  margin: 20px 0px 0px 0px;
}
.export-program__container {
  margin: 20px 0px 0px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}
.export-program__item {
  width: 100%;
  background-color: var(--white-color);
  border: 2px solid var(--border-card-color);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  padding: 20px;
  min-height: 250px;
}
.export-program__title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 1.3;
  text-align: left;
  color: var(--title-color);
}
.export-program__subtitle {
  margin: 10px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.3;
  text-align: left;
  color: var(--subtitle-color);
}
.export-program__errors-list {
  width: 100%;
  padding: 0px;
  list-style-type: none;
}
.export-program__errors-name {
  margin: 15px 0px 5px 0px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
}
.export-program__errors-alert {
  padding: 12px 25px;
  background-color: var(--border-card-color);
  width: calc(100% - 50px);
  border-radius: 7px;
  margin: 0px 0px 10px 0px;
  letter-spacing: 1.2px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
}
.alert_type_success {
  background-color: var(--secondary-color);
  color: var(--white-color);
}
.alert_type_success {
  background-color: rgba(94, 185, 175, .1);
  color: var(--secondary-color);
}
.alert_type_errors {
  background-color: rgba(250, 117, 101, .1);
  color: var(--third-light-color);
}
.alert_type_warnings {
  background-color: rgba(251, 180, 68, .1);
  color: var(--fourth-light-color);
}