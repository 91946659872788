.popup__avatar {
  margin: 0px;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
}
.input__choose-file {
  margin: 30px 0px 20px;
}
.popup__file-input {
  display: none;
}
.popup__avatar-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 120px;
  overflow-y: hidden;
  transition: ease-in-out .3s;
}
.popup__avatar-link-container_type_hide {
  height: 0px;
}
.popup__avatar-separator {
  margin: 0px 0px 15px 0px;
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  font-weight: normal;
}